import Home from "./Home";
import Login from "./Login";
import { Route, Routes } from "react-router-dom";
import React from "react";
import useToken from './useToken';

function App() {
    const { token, setToken } = useToken();

    if(!token) {
        return <Login setToken={setToken} />
    }

    return (
        <div className="App">
            <Routes>
                <Route exact path="/" element={<Home />} />
            </Routes>
        </div>
    );
}

export default App;
