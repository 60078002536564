import React, { useState } from 'react';
import { Container, TextField, Button, Typography, Alert } from '@mui/material';

async function submit(tokenInput) {
    const url = 'https://profloggerserver.develanjaro.com/login'
    return fetch(url,{
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            token: tokenInput
        })
    })
        .then((response) => {
            if(!response.ok) return { token: 'failed'};
            else return response.json();
        })
        .catch((error) => {
            console.log(error);
            alert('ERROR');
        })
}

const Login = ({ setLoggedInEmail, setToken }) => {
    const [tokenInput, setTokenInput] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async e => {
        e.preventDefault();

        const token = await submit({
            tokenInput
        });
        if (token.token === 'failed'){
            setError('Hibás adatok!')
        } else {
            setToken(token);
        }

    }

    return (
        <Container maxWidth="sm">
            <Typography variant="h4" align="center" gutterBottom>
                Bejelentkezés
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="token"
                    label="Access Token"
                    type="password"
                    id="token"
                    autoComplete="current-password"
                    onChange={(e) => setTokenInput(e.target.value)}
                />
                {error && <Alert severity="error">{error}</Alert>}
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Belépés
                </Button>
            </form>
        </Container>
    );
}

export default Login;
